


















































































import { Component, Prop, Watch } from "vue-property-decorator";
import { BaseFormComponent, InputFileCpt, ButtonCpt } from "@/core/components/common";
import { ValidateImportingModel } from "@/core/webapi";
import { NotificationProvider, TrainingService } from "@/core/services";

@Component({
  components: {
    InputFileCpt,
    ButtonCpt,
  },
})
export default class ImportTrainingResultDialogCpt extends BaseFormComponent {
  @Prop() yearId: number;
  @Prop() showDialog: boolean;

  validationResult = new ValidateImportingModel({
    message: "",
    isValid: false,
  });
  model = this.getNewModel();
  show = false;

  get canUpload() {
    return this.validationResult && this.validationResult.isValid;
  }

  get trainingExampleFile() {
    return require("@/assets/blackboard_results_sample.csv");
  }

  @Watch("showDialog")
  onShow() {
    this.show = this.showDialog;
  }

  async created() {
    this.show = this.showDialog;
  }

  async onSubmitForm(): Promise<void> {
    this.isLoading = true;
    try {
      // bundle file into a form
      const form = new FormData();
      form.append("file", this.model.file[0]);

      await TrainingService.importTrainingResults(this.yearId, form);
      NotificationProvider.success("Blackboard training results have been successfully imported.");
      this.close(true);
    } finally {
      this.isLoading = false;
    }
  }

  getNewModel() {
    return {
      file: [],
    } as any;
  }

  async validateFile() {
    this.resetValidation();
    if (this.model.file.length <= 0) {
      return;
    }

    this.isLoading = true;
    try {
      // bundle file into a form
      const form = new FormData();
      form.append("file", this.model.file[0]);

      const result = await TrainingService.validateTrainingResultsImporting(form);
      this.validationResult = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  cancel() {
    this.close(false);
  }

  resetValidation() {
    this.validationResult.message = "";
    this.validationResult.isValid = false;
  }

  close(result: boolean) {
    this.resetValidation();
    this.model = this.getNewModel();
    this.show = false;
    this.$emit("closed", result);
  }
}
