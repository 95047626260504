
















































































import { Component, Prop } from "vue-property-decorator";
import { TrainingSessionModel } from "@/core/webapi";
import AppVue from "@/AppVue.vue";
import { PagedCollection } from "@/core/models";
import { ButtonCpt, TrainingSessionStatusChipCpt } from "@/core/components/common";
import PrintTrainingSheetButtonCpt from "./PrintTrainingSheetButtonCpt.vue";

@Component({
  components: {
    ButtonCpt,
    TrainingSessionStatusChipCpt,
    PrintTrainingSheetButtonCpt,
  },
})
export default class TrainingSessionSearchTableCpt extends AppVue {
  @Prop() collection: PagedCollection<TrainingSessionModel>;
}
