













import { Component, Prop } from "vue-property-decorator";
import { VolunteerTrainingRecordModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import AppVue from "@/AppVue.vue";
import { TrainingRecordStatus } from "@/core/constants";
import { ConfirmCpt } from "@/core/components";

@Component({
  components: {
    ConfirmCpt,
  },
})
export default class TrainingSessionAttendanceUpdateCpt extends AppVue {
  @Prop() record: VolunteerTrainingRecordModel;
  @Prop() statusId: number;
  @Prop({ default: false }) isCancelled: boolean;

  showConfirm = false;

  get confirmMessage() {
    return `Are you sure you want to update the volunteer ${this.record.volunteerNumber} 
      - ${this.record.firstName} ${this.record.lastName}?`;
  }

  get value() {
    return this.statusId === this.record.lastTrainingRecordStatusId;
  }

  get disabled() {
    return (
      this.isCancelled ||
      this.record.lastTrainingRecordStatusId === TrainingRecordStatus.CancelledByVolunteer ||
      this.statusId === TrainingRecordStatus.CancelledByVolunteer ||
      this.record.lastTrainingRecordStatusId === TrainingRecordStatus.CancelledByStaff
    );
  }

  async updateAttendence(result: boolean) {
    this.showConfirm = false;
    // if user doesn't confirm it, it is disabled or already ticked it should not update
    if (!result || this.disabled || this.value) {
      return;
    }

    NotificationProvider.loadingStart("Updating attendance");
    try {
      await ApiService.training().updateTrainingRecordStatus(this.record.trainingRecordId, this.statusId);
      this.$emit("attendence-updated");
      NotificationProvider.success("Volunteer attendance successfully saved");
    } finally {
      NotificationProvider.loadingStop();
    }
  }
}
