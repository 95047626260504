





























































import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import AppVue from "@/AppVue.vue";
import { ApiService, PrintService } from "@/core/services";
import { TrainingSessionPrintSignInSheetModel } from "@/core/webapi";
import { ButtonCpt } from "@/core/components/common";

@Component({
  components: {
    ButtonCpt,
  },
})
export default class PrintTrainingSheetButtonCpt extends AppVue {
  @Prop() trainingSessionId: number;
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: true }) small: boolean;

  @Watch("trainingSessionId")
  onTrainingSessionChanged() {
    this.model = null;
  }

  model: TrainingSessionPrintSignInSheetModel = null;

  getPrintedAt() {
    return moment().local();
  }

  async printSheet() {
    if (!this.model) {
      await this.loadModel();
    }
    this.print();
  }

  print() {
    PrintService.print(`print-sheet-${this.trainingSessionId}`, `TrainingSession_${this.trainingSessionId}`);
  }

  async loadModel() {
    this.isLoading = true;
    try {
      const response = await ApiService.training().getPrintSignInSheet(this.trainingSessionId);
      this.model = response.data;
    } finally {
      this.isLoading = false;
    }
  }
}
