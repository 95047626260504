























































































































import { Component, Prop } from "vue-property-decorator";
import { TrainingSessionSaveLookups, TrainingSessionSaveModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import {
  BaseFormComponent,
  ButtonCpt,
  InputDateTimeCpt,
  InputAutocompleteCpt,
  InputNumberCpt,
  AcademicYearSelectCpt,
} from "@/core/components/common";
import PrintTrainingSheetButtonCpt from "./PrintTrainingSheetButtonCpt.vue";

@Component({
  components: {
    ButtonCpt,
    InputDateTimeCpt,
    InputAutocompleteCpt,
    InputNumberCpt,
    PrintTrainingSheetButtonCpt,
    AcademicYearSelectCpt,
  },
})
export default class TrainingSessionEditFormCpt extends BaseFormComponent {
  @Prop() lookups: TrainingSessionSaveLookups;
  @Prop() model: TrainingSessionSaveModel;
  @Prop({ default: false }) loading: boolean;

  async onSubmitForm(): Promise<void> {
    try {
      await ApiService.training().save(this.model);
      NotificationProvider.success("Training session successfully saved");
      this.$router.push("/training-session-search");
    } catch (err) {
      if (err.response && err.response.data) {
        this.model.modelState = err.response.data.modelState;
      }
    }
  }
}
