
























import { Component, Prop } from "vue-property-decorator";
import { ApiService, NotificationProvider } from "@/core/services";
import AppVue from "@/AppVue.vue";
import { ButtonCpt, ConfirmCpt } from "@/core/components/common";

@Component({
  components: {
    ButtonCpt,
    ConfirmCpt,
  },
})
export default class CancelTrainingSessionCpt extends AppVue {
  @Prop() trainingSessionId: number;
  @Prop({ default: false }) loading: boolean;

  showConfirm = false;

  get cardText() {
    return "Please make sure to let all scheduled volunteers know this session has been cancelled.";
  }

  get confirmMessage() {
    return `<p>Are you sure you want to cancel this training session?</p>
              <p><strong>${this.cardText}</strong></p>`;
  }

  async cancelSession(result: boolean) {
    this.showConfirm = false;
    if (!result) {
      return;
    }

    this.isLoading = true;
    try {
      await ApiService.training().cancelTrainingSession(this.trainingSessionId);
      this.$emit("session-cancelled");
      this.$eventHub.$emit("session-cancelled");
      NotificationProvider.success("Training session successfully cancelled");
    } finally {
      this.isLoading = false;
    }
  }
}
