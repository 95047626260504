







































































import { Component, Prop } from "vue-property-decorator";
import { VolunteerTrainingRecordModel, TrainingSessionAttendenceLookups } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import AppVue from "@/AppVue.vue";
import TrainingSessionAttendanceUpdateCpt from "./TrainingSessionAttendanceUpdateCpt.vue";

@Component({
  components: {
    TrainingSessionAttendanceUpdateCpt,
  },
})
export default class TrainingSessionAttendanceTableCpt extends AppVue {
  @Prop() trainingSessionId: number;
  @Prop() isCancelled: boolean;
  @Prop({ default: false }) loading: boolean;

  records: VolunteerTrainingRecordModel[] = [];
  lookups = new TrainingSessionAttendenceLookups({ trainingRecordStatuses: [] });

  async created() {
    await this.loadLookups();
    await this.loadRecords();

    this.$eventHub.$on("session-cancelled", () => {
      this.loadRecords();
    });
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.training().getTrainingSessionAttendenceLookups();
      this.lookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  async loadRecords() {
    this.isLoading = true;
    try {
      const response = await ApiService.training().getVolunteerTrainingRecord(this.trainingSessionId);
      this.records = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  copyEmailsToClipboard() {
    const emails = this.records.map(p => p.email).filter(p => !!p);
    navigator.clipboard.writeText(emails.join(";"));
    NotificationProvider.success("Emails copied to clipboard!");
  }
}
